import { useUserContext } from "../../contexts/UserContext";

import { FaFacebookF } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";

import './footer.css';

export default function NewFooter() {
  const { personalizationData, contactData, businessAvailabilityData, businessData } = useUserContext();

  const daysOfWeek = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];

  return (

    <footer className="footer">
      <div className="contact-description">
        <div className="container">
          <div className="row" style={{justifyContent: 'center'}}>
            
            <div className="col-md-4 offset-md-1">
              <div className="item opening">
                <h3 className="footer-title" style={{textAlign: 'center'}}>Horários</h3>
                <ul>
                  {Object.entries(businessAvailabilityData ? businessAvailabilityData : {}).map(([timeRange, days]) => (
                    days.map(day => (
                      <li key={day}>
                        <div className="tit">{daysOfWeek[day]}</div>
                        <div className="dots"></div> <span>{timeRange}</span>
                      </li>
                    ))
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-4 offset-md-1">
              <div className="item opening">
                <h3 className="footer-title" style={{textAlign: 'center'}}>Contato</h3>
                <p className="footer-contact-text"> {contactData?.address}, {contactData?.cep} </p>
                <div className="footer-contact-info">
                  <p className="footer-contact-phone">{contactData?.phone}</p>
                  <p className="footer-contact-mail">{contactData?.email}</p>
                </div>

                {(contactData?.show_cnpj_cpf_website && <div>
                  <span>
                    {businessData?.cnpj_cpf}
                  </span>
                </div>)}
                    
                {(contactData) && (contactData.facebook || contactData.twitter || contactData.instagram || contactData.google) && (<div className="footer-about-social-list" style={{color: personalizationData?.salon_color, display: "flex"}}>
                  {contactData.facebook && (<div style={{marginRight: "15px"}}> <a href={contactData.facebook} target="_blank" rel="noreferrer"> <FaFacebookF /> </a></div>)}
                  {contactData.google && (<div style={{marginRight: "15px"}}> <a href={contactData.google} target="_blank" rel="noreferrer"> <FaGoogle /> </a></div>)}
                  {contactData.instagram && (<div style={{marginRight: "15px"}}> <a href={contactData.instagram} target="_blank" rel="noreferrer"> <FaInstagram /> </a></div>)}
                  {contactData.twitter && (<div style={{marginRight: "15px"}}> <a href={contactData.twitter} target="_blank" rel="noreferrer"> <BsTwitter /> </a></div>)}
                </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-bottom-inner">
                <p className="footer-bottom-copy-right" style={{textAlign: 'center'}}>2024 © Todos os direitos reservados. Desenvolvido por <a href="https://www.amarko.com.br">AMarko</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </footer>

  );
}
