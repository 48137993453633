import {
  StyledModal,
  ModalHeader,
  AddCancelServices,
  Title,
  DateStatus,
  modalStyles,
} from "./style";
import { IoClose } from "react-icons/io5";
import { useState } from "react";
import * as api from "../../services/api";
import { useUserContext } from "../../contexts/UserContext";
import renderToast from "../../utils/renderToast";
import { renderDotsLoading } from "../../utils/renderDotsLoading";
import { getServicesId, sumDurations, sumDurationsInteger } from "./auxiliarFunctions";
import ScheduleContainer from "./ScheduleContainer";
import Calendar from "../Calendar";
import dayjs from "dayjs";
import Modal from "../Modal";

import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';

export default function ReservationModal({
  reservationModalIsOpen: openModal,
  setReservationModalIsOpen: setOpenModal,
  reservationsList,
  setReservationsList,
  setIsChoosingMoreServices,
}) {
  const { userData, slugData, apiToken, personalizationData, professionalsData } = useUserContext();

  const [scheduleArray, setScheduleArray] = useState(null);
  const [scrollX, setScrollX] = useState(0);
  const [selectedTime, setSelectedTime] = useState("");
  const [day, setDay] = useState();
  const [loadingSchedule, setLoadingSchedule] = useState(false);

  const [professionalSelectValue, setProfissionalSelectValue] = useState(0);

  const handleChangeProfissional = (event) => {
    setProfissionalSelectValue(event.target.value);
  };

  function closeModal(resetReservations = true) {
    document.body.style.overflow = "unset";
    setOpenModal(false);
    setScheduleArray(null);
    setDay();
    setProfissionalSelectValue(0);
    if (resetReservations) {
      setReservationsList([]);
      setIsChoosingMoreServices(false);
    } else {
      setIsChoosingMoreServices(true);
    }
  }

  async function handleCalendarClick(e) {

    if(professionalSelectValue == 0){
      alert("Selecione um profissional!");
      return;
    }

    const startTime = dayjs(e).toISOString();
    const endTime = dayjs(e).add(1, "day").add(-1, "m").toISOString();

    console.log(dayjs(e));

    setLoadingSchedule(true);
    const response = await api.checkAvailability(apiToken, {
      startTime,
      endTime,
      weekDay: dayjs(e)["$W"],
      date: dayjs(e)["$d"],
      professionalId: professionalSelectValue
    }, slugData);
    setLoadingSchedule(false);
    setTimeout(scrollToBottom, 0);

    setDay(e);
    setScrollX(0);
    setSelectedTime("");

    if (response.status === 200) return setScheduleArray(response.data.availability);

    return renderToast(
      "error",
      "Erro ao carregar os horários, por favor tente novamente"
    );
  }

  function scrollToBottom() {
    const modal = document.getElementById("modal");
    modal.style.scrollBehavior = "smooth";
    modal.scrollTo(0, modal.scrollHeight);
  }

  async function handleReservation() {

    if(professionalSelectValue == 0){
      alert("Selecione um profissional!");
      return;
    }

    const [hour, minute] = selectedTime.split(":");
    
    const startTime = dayjs(day).add(hour, "h").add(minute, "m").toISOString();

    console.log(reservationsList);
    console.log(userData);

    const response = await api.createCalendarEvent(apiToken, {
      startTime,
      duration: sumDurationsInteger(reservationsList),
      customerId: userData?.id,
      description: "",
      servicesId: getServicesId(reservationsList),
      professionalId: professionalSelectValue
    }, slugData);
    
    closeModal();

    if (response.status === 201)
      return renderToast("success", "Horário agendado!");

    return renderToast(
      "error",
      "Erro ao agendar seu horário, por favor tente novamente."
    );
  }

  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      onRequestClose={closeModal}
      style={modalStyles}
      personalizationData={personalizationData}
    >
      <ModalHeader>
        {reservationsList.map((data, i, arr) => (
          <Title fc={"white"} key={i}>
            {i === arr.length - 1 ? data?.title : `${data?.title} + `}
          </Title>
        ))}
      </ModalHeader>

      <Form.Select value={professionalSelectValue} onChange={handleChangeProfissional} style={{width: "calc(57px * 7)", height: "50px", backgroundColor: "var(--bg-color-dark)", color: "var(--main-color-dark)", textAlign: 'center'}} aria-label="profissional-select" required={true}>
        <option key={0} value={0}>Selecione um profissional</option>
        {professionalsData?.map((professional) => {
          return(<option key={professional.id} value={professional.id}>{professional.name}</option>)
        })}
      </Form.Select>

      <Calendar handleCalendarClick={handleCalendarClick} personalizationData={personalizationData} />

      {scheduleArray === null && (
        <DateStatus fc={"white"}>Selecione uma data para reserva</DateStatus>
      )}
      {scheduleArray?.length === 0 && (
        <DateStatus fc={"white"}>Não temos horários disponíveis nessa data</DateStatus>
      )}
      {loadingSchedule && <DateStatus>{renderDotsLoading()}</DateStatus>}
      {!loadingSchedule && scheduleArray?.length > 0 && (
        <>
          <DateStatus fc={"white"}>Selecione um horário :</DateStatus>
          <ScheduleContainer
            scrollX={scrollX}
            setScrollX={setScrollX}
            scheduleArray={scheduleArray}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            handleReservation={handleReservation}
            reservationsList={reservationsList}
            personalizationData={personalizationData}
          />
        </>
      )}
      <AddCancelServices>
        <Button style={{width: "46%", height: "100%"}} variant="outline-light" onClick={closeModal}>Cancelar</Button>
        <Button style={{width: "46%", height: "100%"}} variant="outline-light" onClick={() => closeModal(false)}>
          Escolher mais serviços
        </Button>
      </AddCancelServices>
    </Modal>
  );
}
