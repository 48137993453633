import { useState, createContext, useContext, useCallback } from "react";
import * as api from "../services/api";

const UserContext = createContext();

export function UserContextProvider(props) {
  const [apiToken, setApiToken] = useState(localStorage.getItem("apiToken"));
  const [customerToken, setCustomerToken] = useState(localStorage.getItem("customerToken"));
  const [loadingUserValidation, setLoadingUserValidation] = useState(true);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [userIsNewUser, setUserIsNewUser] = useState(false);
  const [userData, setUserData] = useState();
  const [businessData, setBusinessData] = useState();
  const [personalizationData, setpersonalizationData] = useState();
  const [slugData, setSlugData] = useState();
  const [homeAboutData, setHomeAboutData] = useState();
  const [servicesData, setServicesData] = useState();
  const [productsData, setProductsData] = useState();
  const [aboutData, setAboutData] = useState();
  const [feedbacksData, setFeedbacksData] = useState();
  const [contactData, setContactData] = useState();
  const [businessAvailabilityData, setBusinessAvailabilityData] = useState();
  const [professionalsData, setProfessionalsData] = useState();
  const [useChabotBot, setUseChatBot] = useState();
  

  async function validateApiToken(apiToken) {

    if(apiToken){

      const response_api_token = await api.validateApiToken(apiToken);

      if(response_api_token && response_api_token.code == 200){
        await validateCustomerToken(customerToken);
        return;
      }else{

        const response_api_token_renew = await api.createApiToken();

        if(response_api_token_renew && response_api_token_renew.token){
          localStorage.setItem("apiToken", response_api_token_renew.token);
          setApiToken(response_api_token_renew.token);
          await validateCustomerToken(customerToken);
          window.location.reload();
          return;
        }
      }
    }else{
      const response_api_token = await api.createApiToken();

      if(response_api_token && response_api_token.token){
        localStorage.setItem("apiToken", response_api_token.token);
        setApiToken(response_api_token.token);
        await validateCustomerToken(customerToken);
        return;
      }
    }

    localStorage.removeItem("apiToken");
    setApiToken(null);
    return;
  }

  async function validateCustomerToken(customerToken){

    if(customerToken){

      const response_user = await api.validateCustomerToken(customerToken, apiToken);

      if (response_user.code === 200) {
        setUserIsLoggedIn(true);
        setUserData(response_user.customer);
        setLoadingUserValidation(false);
        // if (apiToken.customer.newUser) setUserIsNewUser(true);
        localStorage.setItem("customerToken", customerToken);
        return response_user.customer;
      }
    }

    setUserIsLoggedIn(false);
    setLoadingUserValidation(false);
  }

  async function removeCustomerData(){
    setUserIsLoggedIn(false);
    setUserData(null);
    setCustomerToken(null);
    localStorage.removeItem("customerToken");
  }

  async function setBusinessResponse(slug){
    setSlugData(slug);

    const response = await api.getResponse(slug, (apiToken || localStorage.getItem("apiToken")));

    setBusinessData(response.business);
    setUseChatBot(response.chatbot_enabled);
    setpersonalizationData(response.personalization);
    setHomeAboutData(response.home_about);
    setServicesData(response.services);
    setProductsData(response.products);
    setAboutData(response.about);
    setFeedbacksData(response.clients_feedbacks);
    setContactData(response.contact);
    setBusinessAvailabilityData(response.business_availability);
    setProfessionalsData(response.professionals);
  }

  return (
    <UserContext.Provider
      value={{
        validateApiToken,
        validateCustomerToken,
        apiToken,
        setApiToken,
        customerToken,
        setCustomerToken,
        loadingUserValidation,
        setLoadingUserValidation,
        userIsLoggedIn,
        setUserIsLoggedIn,
        userIsNewUser,
        setUserIsNewUser,
        userData,
        setUserData,
        businessData,
        slugData,
        personalizationData,
        homeAboutData,
        servicesData,
        productsData,
        aboutData,
        feedbacksData,
        contactData,
        setSlugData,
        setBusinessResponse,
        businessAvailabilityData,
        professionalsData,
        removeCustomerData,
        useChabotBot
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
