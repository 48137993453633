import HeaderSection from "../../components/HeaderSection";
import ProfileConfigSection from "../../components/ProfileConfigSection";
import { useState, useEffect } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function ProfileConfigPage() {
  const { userIsLoggedIn, loadingUserValidation, setBusinessResponse, slugData, apiToken, validateApiToken } = useUserContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!userIsLoggedIn && !loadingUserValidation) {
      navigate("/404");
    }
  }, [loadingUserValidation]);

  const { slug } = useParams();

  useEffect(async () => {
    if(!apiToken){
      await validateApiToken(apiToken);
    }
    
    if(!slugData){
      setBusinessResponse(slug);
    }
  }, []);

  return (
    <>
      <HeaderSection page="profile" title="Configurações" />
      <ProfileConfigSection/>
    </>
  );
}
