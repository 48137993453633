import styled from "styled-components";
import * as variables from "../../styles/variables";

export const Container = styled.div`
  width: 100%;

  background-color: ${(props) => (props.dbc ? props.dbc : variables.DARK_GREY)};

  padding-bottom: 0px;
`;

export const Category = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 112px;

  @media (max-width: 750px) {
    padding-top: 50px;
  }
`;

export const Title = styled.p`
  font-family: "Teko";
  font-size: 4.375rem;
  font-weight: 500;
  line-height: 1em;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: ${(props) => (props.sc ? props.sc : variables.DARK_YELLOW)};

  @media (max-width: 750px) {
    font-size: 2.575rem;
  }

  @media (max-width: 350px) {
    font-size: 2.275rem;
  }
`;

export const Services = styled.div`
  width: 1170px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;

  padding-top: 80px;

  @media (max-width: 1270px) {
    width: 94%;
  }

  @media (max-width: 1023px) {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  @media (max-width: 1023px) {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  @media (max-width: 750px) {
    padding-top: 42px;

    grid-template-columns: 1fr;
  }
`;

export const Service = styled.div`
  width: 100%;
  height: 230px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${(props) => (props.ldbc ? props.ldbc : variables.LESS_DARK_GREY)};

  position: relative;

  padding-bottom: 70px;

  @media (max-width: 1023px) {
    height: 180px;
  }
`;

export const NamePrice = styled.div`
  font-size: 1.488rem;
  font-weight: 500;
  line-height: 1em;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => (props.fc ? props.fc : variables.WHITE)};

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 16px;

  p {
    :first-of-type {
      width: 60%;
    }
  }

  @media (max-width: 1023px) {
    height: 70px;

    font-size: 1.088rem;
  }
`;

export const Description = styled.div`
  color: ${(props) => (props.dc ? props.dc : variables.LIGHT_GREY)};

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 20px;

  p {
    :first-of-type {
      width: 60%;
    }
  }

  @media (max-width: 1023px) {
    height: 70px;

    font-size: 1.088rem;
  }
`;

export const ReadMore = styled.button`
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  color: ${(props) => (props.sc ? props.sc : variables.DARK_YELLOW)};

  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 0;

  position: absolute;
  bottom: 75px;
  right: 6%;

  cursor: pointer;

  @media (max-width: 1023px) {
    font-size: 0.675rem;
  }
`;

export const ButtonContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;

padding-top: 16px;

p {
  :first-of-type {
    width: 60%;
  }
}

@media (max-width: 1023px) {
  height: 70px;

  font-size: 1.088rem;
}
`;

export const Duration = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.7px;
`;