import { useState, useRef } from "react";
import renderToast from "../../utils/renderToast";
import { renderDotsLoading } from "../../utils/renderDotsLoading";
import { Container, Title, InputsForm, Button } from "./style";
import * as api from "../../services/api";
import { useNavigate } from "react-router-dom";
import PasswordInput from "../PasswordInput";
import handleApiErrors from "../../utils/handleApiErrors";
import { useUserContext } from "../../contexts/UserContext";

export default function ResetPasswordForm({ hash }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const passwordRef = useRef(null);
  const passwordConfirmationRef = useRef(null);

  const { personalizationData } = useUserContext();

  async function resetPassword(e) {
    e.preventDefault();

    setLoading(true);
    const response = await api.resetPassword(hash, {
      password: passwordRef.current.value,
      passwordConfirmation: passwordConfirmationRef.current.value,
    });
    setLoading(false);

    if (response.status === 200) {
      navigate("/");
      renderToast("success", "Senha alterada com sucesso!");
      return;
    }

    handleApiErrors(response);
    return;
  }

  return (
    <Container dbc={personalizationData?.dark_background_color}>
      <Title sc={personalizationData?.salon_color}>Redefinir senha</Title>
      <InputsForm>
        <PasswordInput placeholder={"senha"} reference={passwordRef} personalizationData={personalizationData} />
        <PasswordInput
          placeholder={"confirme sua senha"}
          reference={passwordConfirmationRef}
          personalizationData={personalizationData}
        />

        {loading ? (
          <Button fc={personalizationData?.font_color} sc={personalizationData?.salon_color} type="button" disabled>
            {renderDotsLoading()}
          </Button>
        ) : (
          <Button fc={personalizationData?.font_color} sc={personalizationData?.salon_color} onClick={resetPassword}>Redefinir</Button>
        )}
      </InputsForm>
    </Container>
  );
}
