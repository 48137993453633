import {
  Container,
  BackgroundImage,
  BackgroundDarkness,
  Title,
  Button,
} from "./style";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";

export default function HomeToContactUsSection() {
  const navigate = useNavigate();
  const { personalizationData, slugData } = useUserContext();

  return (
    <Container dbc={personalizationData?.dark_background_color}>
      {personalizationData?.wallpaper_smart?.url ? <BackgroundImage src={`${personalizationData?.wallpaper_smart.url}`} alt="" /> : <></>}
      <BackgroundDarkness />

      <Title >Onde nos encontrar?</Title>
      <Button sc={personalizationData?.salon_color} onClick={() => navigate("/"+slugData+"/contato")}>Página de contato</Button>
    </Container>
  );
}
