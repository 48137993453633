import styled from "styled-components";
import * as variables from "../../styles/variables";

export const Container = styled.header`
  width: 100%;
  height: ${(props) => (props.page === "home" ? "960px" : "537px")};

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: rgba(0, 0, 0, 1);
  position: relative;

  z-index: 999;

  @media (max-width: 1100px) {
    height: ${(props) => (props.page === "home" ? "570px" : "302px")};
  }

  @media (max-width: 767px) {
    height: ${(props) => (props.page === "home" ? "500px" : "220px")};
  }

  @media (max-width: 500px) {
    height: ${(props) => (props.page === "home" ? "430px" : "220px")};
  }

  @media (max-width: 426px) {
    height: ${(props) => (props.page === "home" ? "400px" : "220px")};
  }

  @media (max-width: 418px) {
    height: ${(props) => (props.page === "home" ? "450px" : "220px")};
  }

  @media (max-width: 337px) {
    height: ${(props) => (props.page === "home" ? "480px" : "220px")};
  }
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;

  opacity: 0.6;

  position: absolute;
  top: 0;
  left: 0;

  z-index: -3;
`;

export const BackgroundDarkness = styled.div`
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.6);

  position: absolute;
  top: 0;
  left: 0;

  z-index: 0;
`;

export const Nav = styled.nav`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: transparent;
  z-index: 99;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 100px;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &.nav-scroll {
    background: ${(props) => (props.mainColorDark)};
    padding: 0 0 0 0;
    position: fixed;
    top: -100px;
    left: 0;
    width: 100%;
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    height: 90px;

    & .navbar-nav{
      & .active{
        color: ${(props) => (props.mainColorLigth)} !important;
      }
    }
  }
`;

export const NavBarNav = styled.ul`
  & .active{
    color: ${(props) => (props.mainColorLigth)} !important;
  }

  & :hover{
    color: ${(props) => (props.mainColorLigth)} !important;
  }
`;

