import {
  Container,
  AboutContainer,
  ProfileImage,
  InfoContainer,
  BarberName,
  Description,
  Paragraph,
} from "./style";
import SocialIcons from "../SocialIcons";
import { useUserContext } from "../../contexts/UserContext";

export default function AboutSection() {

  const { aboutData, personalizationData } = useUserContext();

  return (
    <section className="about section-padding" data-scroll-index="1">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-30">
              <div className="section-head mb-20">
                <div className="section-title">{aboutData?.title}</div>
              </div>
              
              <p style={{color: personalizationData?.dark_background_color}}>{aboutData?.description}</p>

            </div>
            <div className="col col-md-6 "> <img src={aboutData?.image?.url} alt="" className="mt-90 mb-30" /> </div>
          </div>
        </div>
      </section>
  );
}
