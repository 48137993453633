import HeaderSection from "../../components/HeaderSection";
import HomeToAboutSection from "../../components/HomeToAboutSection";
import HomeToContactUsSection from "../../components/HomeToContactUsSection";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { useEffect } from "react";
import { Button } from "react-bootstrap";

import styled from 'styled-components';

const ChatBubble = styled.div`
  max-width: 300px;
  padding: 10px;
  margin: 10px;
  background-color: #f1f0f0;
  border-radius: 15px;
  position: relative;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #f1f0f0;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
  }
`;

import chatbotLogo from "../../assets/chatbot.png";

export default function HomePage() {

  const { setBusinessResponse, apiToken, validateApiToken, useChabotBot } = useUserContext();
  const { slug } = useParams();

  useEffect(async () => {
    if(!apiToken){
      await validateApiToken(apiToken);
    }

    setBusinessResponse(slug);
  }, []);

  return (
    <>
      <HeaderSection page={"home"} />
        {(useChabotBot == true && <a href={`https://salao.amarko.com.br/${slug}/chat`}> <div style={{zIndex: 999, position: 'fixed', marginLeft: 'auto', marginRight: '15px', display: "inline-grid", bottom: "0", right: "0"}}>
          <ChatBubble>
            Olá! sou <b style={{color: "black"}}>Amarco</b>, <br />
            vamos agendar <br />
            um horário?
          </ChatBubble>

            <img src={chatbotLogo} alt="chatbot" style={{width: '75px', height: '75px', borderRadius: '50%', marginLeft: '10px'}} />
          </div>
        </a>)}
      <HomeToAboutSection />
      <HomeToContactUsSection />
    </>
  );
}
