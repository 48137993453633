import { useState, useRef } from "react";
import { renderDotsLoading } from "../../../utils/renderDotsLoading";
import PasswordInput from "../../PasswordInput";
import renderToast from "../../../utils/renderToast";
import * as api from "../../../services/api";
import {
  InputsForm,
  LinkButtonDiv,
  NavigationText,
  ForgotPassword,
} from "../style";
import { useUserContext } from "../../../contexts/UserContext";
import handleApiErrors from "../../../utils/handleApiErrors";

import Button from 'react-bootstrap/Button';

export default function SignIn({ closeModal, setPage, personalizationData }) {
  const { setCustomerToken, slugData, apiToken, validateCustomerToken } = useUserContext();

  const [loading, setLoading] = useState(false);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  async function signIn(e) {
    e.preventDefault();

    const email = emailRef?.current?.value;
    const password = passwordRef?.current?.value;

    setLoading(true);
    const response = await api.signIn({ email, password }, slugData, apiToken);
    setLoading(false);

    if (response.status === 200) {
      renderToast("success", "Login efetuado!");

      localStorage.setItem("customerToken", response.data.customer.token);
      setCustomerToken(response.data.customer.token);

      //validateCustomerToken(response.data.customer.token);

      closeModal();
      window.location.reload();
      return;
    }else{
      renderToast("error", response.data.errors);
      return;
    }

    //handleApiErrors(response.data);
  }

  return (
    <InputsForm fc={personalizationData?.less_dark_background_color}>
      <input type="email" placeholder="E-mail" ref={emailRef} required />
      <PasswordInput placeholder={"Senha"} reference={passwordRef} personalizationData={personalizationData} />

      <LinkButtonDiv>
        <NavigationText style={{textDecorationLine: "none"}} onClick={() => setPage("cadastrar-se")}>
          <span>Não possuo cadastro</span>
        </NavigationText>

        <ForgotPassword style={{textDecorationLine: "none"}} onClick={() => setPage("redefinir senha")}>
          <span>Esqueci minha senha</span>
        </ForgotPassword>

        {loading ? (
          <Button fc={personalizationData?.font_color} sc={personalizationData?.salon_color} type="button" disabled>
            {renderDotsLoading()}
          </Button>
        ) : (
          <Button style={{
            fontSize: "0.835rem",
            fontWeight: "800",
            lineHeight: "1em",
            letterSpacing: "0.7px",
            textTransform: "uppercase",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "12px 23px"

          }} variant="outline-light" onClick={(e) => signIn(e)}>Entrar</Button>
        )}
      </LinkButtonDiv>
    </InputsForm>
  );
}
