import styled from "styled-components";
import * as variables from "../../styles/variables";

export const InputsForm = styled.form`
  width: 100%;

  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    all: unset;

    width: 100%;
    height: 55px;

    font-size: 0.855rem;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.7px;

    box-sizing: border-box;
    padding: 0 25px;

    margin-bottom: 13px;
    color: var(--main-color-ligth);

    border-radius: 4px;
    border: 2px solid var(--main-color-ligth);

    ::placeholder {
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  span {
    width: 100%;
  }

  @media (max-width: 494px) {
    margin-top: 15px;
  }

  @media (max-width: 424px) {
    input {
      font-size: 0.755rem;
    }
  }
`;