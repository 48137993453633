import {
  Container,
  AboutContainer,
  InfoContainer,
  Title,
  Description,
  Button,
  ProfileImage,
} from "./style";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";

export default function HomeToAboutSection() {
  const navigate = useNavigate();
  const { homeAboutData, personalizationData } = useUserContext();

  return (
    homeAboutData ? (
      <section className="about section-padding" data-scroll-index="1">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-30">
              <div className="section-head mb-20">
                <div className="section-title">{homeAboutData?.title}</div>
              </div>
              
              <p style={{color: personalizationData?.dark_background_color }} >{homeAboutData?.description}</p>

            </div>
            <div className="col col-md-6 " data-animate-effect="fadeInUp"> <img src={homeAboutData?.image?.url} alt="" className="mt-90 mb-30" /> </div>
          </div>
        </div>
      </section>
    ) : <></>
  );
}