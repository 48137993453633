import io from 'socket.io-client';

import HeaderSection from "../../components/HeaderSection";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { useEffect, useState, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Teste from './image.png';
import Teste1 from './image1.png';


import {InputsForm} from "./style";

import ToggleButton from 'react-bootstrap/ToggleButton';

import DatePicker from "react-datepicker";

export default function ChatBotPage() {

  const [currentProfessionalChecked, setCurrentProfessionalChecked] = useState(null);
  const [currentAvailabilityChecked, setCurrentAvailabilityChecked] = useState(null);
  const [allServicesChecked, setAllServicesChecked] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [clientId, setClientId] = useState('');
  const [calendarOpen, setCalendarOpen] = useState(false);
  const socketRef = useRef(null);

  const [startDate, setStartDate] = useState(new Date());

  const { setBusinessResponse, slugData, apiToken, validateApiToken, businessData, userIsLoggedIn, userData, validateCustomerToken } = useUserContext();
  const { slug } = useParams();

  useEffect(async () => {
    if(!apiToken){
      await validateApiToken(apiToken);
    }
    
    if(!slugData){
      setBusinessResponse(slug);
    }

    if(localStorage.getItem("customerToken")){
      setCurrentCustomer(await validateCustomerToken(localStorage.getItem("customerToken")));
      console.log(currentCustomer);
      console.log("FDFDSFDSFDS");
    }else{
      localStorage.setItem("customerToken", null);
    }
  }, []);

  const footer = document.getElementsByClassName('footer');

  if(footer.length){
    footer[0].style.display = "none"
  }

  const body =  document.getElementsByTagName("body");

 

  console.log(currentCustomer);
  console.log("esta logado");

  useEffect(() => {
    if(businessData){

      let socketClientId;
      let customerDataObject = null;

      if(currentCustomer){
        setClientId(currentCustomer.id);
        socketClientId = currentCustomer.id;
        customerDataObject = currentCustomer;
      }else{
        // Generate a unique client ID for this session
        const generatedClientId = `client-${Math.random().toString(36).substr(2, 9)}`;
        setClientId(generatedClientId);
        socketClientId = generatedClientId;
      }

      console.log("disconnect?", socketRef.current?.disconnected);
      console.log("atuyaizado 4", document.visibilityState);

      // Initialize socket connection only once
      if (!socketRef.current || socketRef.current.disconnected) {
        //socketRef.current = io('http://localhost:3000');
        socketRef.current = io('https://backend.amarko.com.br:3030');

        console.log("FOIIII", socketClientId);

        socketRef.current.emit('join', { storeId: businessData?.id, clientId: socketClientId, customerDataObject: customerDataObject, businessSlug: slugData });

        // Listen for messages from the server
        socketRef.current.on('message', (msg) => {
          setMessages((prevMessages) => [...prevMessages, msg]);
          const chatMessages = document.getElementById('messages'); 
          chatMessages.scrollTop = chatMessages.scrollHeight;
        });
      }

      const handleVisibilityChange = () => {       
        if (document.visibilityState === 'visible') {

          socketRef.current.disconnect();

          setMessages([]);

          console.log("reconnect", document.visibilityState);

          socketRef.current = io('https://backend.amarko.com.br:3030');

          socketRef.current.emit('join', { storeId: businessData?.id, clientId: socketClientId, customerDataObject: customerDataObject, businessSlug: slugData });

          // Listen for messages from the server
          socketRef.current.on('message', (msg) => {
            setMessages((prevMessages) => [...prevMessages, msg]);
            const chatMessages = document.getElementById('messages'); 
            chatMessages.scrollTop = chatMessages.scrollHeight;
          });
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }


    // Cleanup the listener on component unmount
    return () => {
      if (socketRef.current) {
      //  socketRef.current.off('message');
        socketRef.current.disconnect();
      }
      //document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [businessData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      const userMessage = { text: `<span style="color: var(--main-color-dark);"><b style="color: var(--main-color-ligth);">Você:</b> ${input}</span>`, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      socketRef.current.emit('message', {msg: input, businessSlug: slugData});
      setInput('');

      const chatMessages = document.getElementById('messages'); 
      chatMessages.scrollTop = chatMessages.scrollHeight;
    }
  };

  const handleSelectProfessional = (professional) => {
    setCurrentProfessionalChecked(professional);
    
    socketRef.current.emit('professional', { storeId: businessData?.id, clientId: clientId, professional: professional, businessSlug: slugData });
  }

  console.log(messages);

  const handleSelectServices = () => {
    socketRef.current.emit('services', { storeId: businessData?.id, clientId: clientId, services: allServicesChecked, businessSlug: slugData });
  }

  const handleSelectAvailability = (hour) => {
    setCurrentAvailabilityChecked(hour);
    
    socketRef.current.emit('availability', { storeId: businessData?.id, clientId: clientId, hour: hour, businessSlug: slugData });
  }

  const handleChangeDate = (date) => {
    setStartDate(date);

    console.log(date);

    const formattedDate = date.toLocaleDateString('pt-BR');
    console.log(formattedDate);

    const userMessage = { text: `<span style="color: var(--main-color-dark);"><b style="color: var(--main-color-ligth);">Você:</b> ${formattedDate} </span>`, sender: 'user' };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    socketRef.current.emit('message', {msg: formattedDate, businessSlug: slugData});
    setInput('');

    const chatMessages = document.getElementById('messages'); 
    chatMessages.scrollTop = chatMessages.scrollHeight;

    //socketRef.current.emit('date', { storeId: businessData?.id, clientId: clientId, date: date });
  }

  const handleActionResumo = (actionType) => {
    socketRef.current.emit('action', { storeId: businessData?.id, clientId: clientId, action: actionType, businessSlug: slugData });
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value);


  return (
    <>
   

   <HeaderSection page="chat-bot" title="🤖 Amarco" />

      <div style={{width: '100%', position: 'fixed', height: '68%'}}>
          
        <div id="messages" style={{overflowY: "auto", height: '90%', backgroundColor: "var(--bg-color)"}}>
          <ul id="messages-list">
            {messages.map((msg, index) => (
              <>
                <li style={{fontSize: "18px", margin: "5px"}} className={msg.sender === 'user' ? 'user' : 'bot'} key={index} dangerouslySetInnerHTML={{ __html: msg.text }} />

                {msg.classification == "informar_telefone" ? <li style={{ display: "flex", width: '95%', marginLeft: '2.5%', overflowX: "auto", gap: '7.5px'}}> {msg.options.map((professional) => {
                  return <div style={{border: 'solid 1px', display: 'flex', flexDirection: 'column', alignItems: 'center'}} key={professional.id}>
                    
                    <div style={{width: '20vh', height: '20vh', borderBottom: 'solid 1px', alignContent: 'center', backgroundColor: 'white'}}> 
                      <img style={{width: '100%', height: '100%'}} src={professional.image?.url} alt='teste' /> 
                    </div>


                    <div style={{fontSize: '13px'}}> {professional.name} </div>

                    <ToggleButton style={{marginBottom: '7%', borderRadius: '0px', width: '75%', height: '23px', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex'}}
                    id={`professional-select-`+professional.id}
                    type="checkbox"
                    variant="outline-primary"
                    checked={currentProfessionalChecked?.id == professional.id ? true : false}
                    value={professional.id}
                    onChange={() => handleSelectProfessional(professional)}
                  >
                  Selecionar
                </ToggleButton>
                 </div>
                })} </li> : <></>}

                {msg.classification == "informar_data" ? <div> <li
                style={{display: "flex", width: "95%", marginLeft: '2.5%', overflowX: "auto"}}> {msg.options.map((availability, index) => {
                  return <div style={{marginRight: "5px"}} key={index}><ToggleButton style={{borderRadius: '30px'}}
                    className="mb-2"
                    id={`availability-select-`+index}
                    type="checkbox"
                    variant="outline-primary"
                    checked={currentAvailabilityChecked == availability ? true : false}
                    value={availability}
                    onChange={() => handleSelectAvailability(availability)}
                  >
                  {availability}
                </ToggleButton>
                </div>
                })}
               
                </li> 
               <Button onClick={() => handleActionResumo("change_day")} style={{ width: '95%', marginLeft: '2.5%', borderRadius: '30px'}} variant="outline-primary">Alterar Data</Button>
               </div>: <></>}

                {msg.classification == "informar_servico" ? <li style={{display: "flex", width: "95%", marginLeft: '2.5%', gap: '7.5%', overflowX: "auto"}}> {msg.options.map((service, index) => {
                  return <div style={{border: 'solid 1px', display: 'flex', flexDirection: 'column', alignItems: 'center'}} key={index}>
                    
                  <div style={{width: '20vh', height: '20vh', borderBottom: 'solid 1px', alignContent: 'center', backgroundColor: 'white'}}> 
                    <img style={{width: '100%', height: '100%'}} src={service.image?.url} alt='teste' /> 
                  </div>

                  <div style={{fontSize: '13px'}}> {service.title} </div> 
                  
                  <div style={{width: '100%', display: 'flex', justifyContent: 'space-around'}}>
                  <span>{numberFormat(service.price)}</span>
                  <ToggleButton style={{marginBottom: '6%', borderRadius: '0px', width: '50%', height: '23px', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex'}}
  
                    id={`service-select-`+index}
                    type="checkbox"
                    variant="outline-primary"
                    checked={allServicesChecked.includes(service.id) ? true : false}
                    value={service.id}
                    onClick={() => {
                      if(allServicesChecked.includes(service.id)){
                        const newServices = allServicesChecked.filter((item) => item != service.id);
                        setAllServicesChecked(newServices);
                      } else {
                        setAllServicesChecked([...allServicesChecked, service.id]);
                      }
                    }}
                  >
                  Selecionar
                </ToggleButton>
                </div>
                </div>
                })} </li> : <></>}

                {msg.classification == "informar_servico" ? <div style={{width: '95%', marginLeft: '2.5%', gap: '10px', display: 'flex', flexDirection: 'column'}}> 
                  <Button onClick={() => handleActionResumo("change_professional")} style={{ width: '100%', borderRadius: '30px'}} variant="outline-secondary">Alterar Profissional</Button>
                  <Button style={{ width: '100%', borderRadius: '30px'}} onClick={handleSelectServices} variant="outline-primary">Avançar</Button> 
                  </div> : <></>}
                
                {msg.classification == "informar_dia" ? <div> <li style={{fontSize: "20px", margin: "5px"}} className={msg.sender === 'user' ? 'user' : 'bot'}>
                  <div style={{display: "inline"}}> <span>Selecione a data</span>
                    <DatePicker
                    selected={startDate}
                    onChange={(date) => handleChangeDate(date)}
                    locale="pt-BR"
                    className="custom-calendar-bot"
                  /> 
                  </div>
                </li> 
                <Button onClick={() => handleActionResumo("change_services")} style={{width: '95%', marginLeft: '2.5%', borderRadius: '30px'}} variant="outline-secondary">Alterar Serviços</Button>
                </div>: <></>}
                

                {msg.classification == "resumo" ? <div style={{ width: "95%", marginLeft: '2.5%', display: 'flex', flexDirection: 'column', gap: '10px'}}>     
                  <Button onClick={() => handleActionResumo("confirm")} style={{ width: '100%', borderRadius: '30px'}} variant="outline-primary">Confirmar Agendamento</Button>
                  <Button onClick={() => handleActionResumo("change_professional")} style={{ width: '100%', borderRadius: '30px'}} variant="outline-primary">Alterar Profissional</Button>
                  <Button onClick={() => handleActionResumo("change_services")} style={{width: '100%', borderRadius: '30px'}} variant="outline-primary">Alterar Serviços</Button>
                  <Button onClick={() => handleActionResumo("change_day")} style={{ width: '100%', borderRadius: '30px'}} variant="outline-primary">Alterar Data</Button>
                  <Button onClick={() => handleActionResumo("change_time")} style={{ width: '100%', borderRadius: '30px'}} variant="outline-primary">Alterar Horário</Button>
                  <Button onClick={() => handleActionResumo("cancel")} style={{ width: '100%', borderRadius: '30px', color: '#ff0000a3'}} variant="outline-primary">Cancelar Agendamento</Button>                                
                 </div> : <></>}
              </>
            ))}
          </ul>
        </div>
      
       

        <div style={{display: "flex", boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)", position: "fixed", bottom: "0%", width: '100%', height: '10%', backgroundColor: 'var(--bg-color)'}}>

          <InputsForm style={{marginBottom: "15px", display: "flex", flexDirection: "row", alignItems: "center"}}>
            <input style={{borderRadius: '15px', marginLeft: "5%", marginRight: "10px", height: "100%", alignSelf: 'normal', padding: '0px 15px'}} id="input" placeholder='Digite sua resposta...' value={input} onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(e);
              }
            }} onChange={(e) => setInput(e.target.value)} autoComplete="off"/>
            <Button onClick={handleSubmit} style={{width: "30%", marginRight: "10px", marginRight: "5%", height: "100%", color: "var(--main-color-dark)", backgroundColor: "var(--bg-color-dark)"}} variant="dark">Enviar</Button>
          </InputsForm>
        </div>

       </div>
    </>
  );
}