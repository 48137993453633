import { useState, useRef } from "react";
import { renderDotsLoading } from "../../../utils/renderDotsLoading";
import * as api from "../../../services/api";
import renderToast from "../../../utils/renderToast";
import {
  InputsForm,
  Description,
  LinkButtonDiv,
  NavigationText,
  Button,
} from "../style";
import handleApiErrors from "../../../utils/handleApiErrors";

export default function ResetPassword({ closeModal, setPage, personalizationData }) {
  const [loading, setLoading] = useState(false);

  const emailRef = useRef(null);

  async function sendRecuperationEmail(e) {
    e.preventDefault();

    const email = emailRef?.current?.value;

    setLoading(true);
    const response = await api.sendRecuperationEmail(email);
    setLoading(false);

    if (response.status === 200) {
      renderToast("success", `Email de recuperação enviado para ${email}`);
      closeModal();
      return;
    }

    handleApiErrors(response);
    return;
  }

  return (
    <>
      <Description fc={personalizationData?.font_color}>
        Insira seu email cadastrado para recuperar sua senha
      </Description>
      <InputsForm fc={personalizationData?.font_color}>
        <input type="email" placeholder="E-mail" ref={emailRef} required />

        <LinkButtonDiv>
          <NavigationText sc={personalizationData?.salon_color} fc={personalizationData?.font_color} style={{textDecorationLine: "none"}} onClick={() => setPage("entrar")}>
            Já possuo cadastro
          </NavigationText>

          {loading ? (
            <Button sc={personalizationData?.salon_color} fc={personalizationData?.font_color} type="button" disabled>
              {renderDotsLoading()}
            </Button>
          ) : (
            <Button sc={personalizationData?.salon_color} fc={personalizationData?.font_color} onClick={(e) => sendRecuperationEmail(e)}>Enviar</Button>
          )}
        </LinkButtonDiv>
      </InputsForm>
    </>
  );
}
