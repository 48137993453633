import { useState } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { Title, ActionButtons, modalStyles } from "./style";
import * as api from "../../services/api";
import renderToast from "../../utils/renderToast";
import { renderDotsLoading } from "../../utils/renderDotsLoading";
import Modal from "../Modal";

import Button from 'react-bootstrap/Button';

export default function CancelReservationModal({
  openModal,
  setOpenModal,
  renderPage,
  eventId,
}) {
  const { apiToken, slugData, userData, personalizationData } = useUserContext();

  const [loading, setLoading] = useState(false);

  function closeModal() {
    document.body.style.overflow = "unset";
    setOpenModal(false);
  }

  async function handleCancelReservation() {
    setLoading(true);
    const promise = await api.deleteReservation(apiToken, slugData, eventId, userData.id);
    setLoading(false);
    closeModal();
    renderPage();

    if (promise.status === 200) {
      return renderToast("success", "Agendamento cancelado com sucesso");
    }

    return renderToast(
      "error",
      "Erro ao cancelar agendamento, por favor tente mais tarde"
    );
  }

  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      onRequestClose={closeModal}
      style={modalStyles}
      personalizationData={personalizationData}
    >
      <h4 style={{color: "white"}}>Tem certeza que deseja cancelar esse agendamento?</h4>

      {loading ? (
        <ActionButtons>
          <Button style={{fontSize: "0.835rem", fontWeight: "800", lineHeight: "1em", letterSpacing: "0.7px", textTransform: "uppercase", display: "flex", justifyContent: "center", alignItems: "center", padding: "12px 58px", borderRadius: "4px"}} variant="outline-light">Cancelar</Button>
          <Button style={{fontSize: "0.835rem", fontWeight: "800", lineHeight: "1em", letterSpacing: "0.7px", textTransform: "uppercase", display: "flex", justifyContent: "center", alignItems: "center", padding: "12px 58px", borderRadius: "4px"}} variant="outline-light">{renderDotsLoading()}</Button>
        </ActionButtons>
      ) : (
        <ActionButtons>
          <Button style={{fontSize: "0.835rem", fontWeight: "800", lineHeight: "1em", letterSpacing: "0.7px", textTransform: "uppercase", display: "flex", justifyContent: "center", alignItems: "center", padding: "12px 58px", borderRadius: "4px"}} variant="outline-light" onClick={closeModal}>Cancelar</Button>
          <Button style={{fontSize: "0.835rem", fontWeight: "800", lineHeight: "1em", letterSpacing: "0.7px", textTransform: "uppercase", display: "flex", justifyContent: "center", alignItems: "center", padding: "12px 58px", borderRadius: "4px"}} variant="outline-light" onClick={handleCancelReservation}>Confirmar</Button>
        </ActionButtons>
      )}
    </Modal>
  );
}
