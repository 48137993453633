import { useCallback, useEffect, useState } from "react";
import { useUserContext } from "../../contexts/UserContext";
import * as api from "../../services/api";
import renderToast from "../../utils/renderToast";
import HeaderSection from "../../components/HeaderSection";
import ProfileReservationsSection from "../../components/ProfileReservationsSection";
import Loading from "../../components/Loading";
import CancelReservationModal from "../../components/CancelReservationModal";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function ProfileReservationsPage() {
  const { apiToken, userIsLoggedIn, loadingUserValidation, slugData, setBusinessResponse, userData, validateApiToken } = useUserContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!userIsLoggedIn && !loadingUserValidation) {
      navigate("/404");
    }else{
      renderPage();
    }
  }, [loadingUserValidation]);

  const { slug } = useParams();
  
  useEffect(async () => {
    if(!apiToken){
      await validateApiToken(apiToken);
    }
    
    if(!slugData){
      setBusinessResponse(slug);
    }
  }, []);

  const [reservationsArray, setReservationsArray] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [eventId, setEventId] = useState();

  const renderPage = useCallback(async () => {
    const promise = await api.getReservations(apiToken, slug, userData?.id);

    if (promise.status === 200) {
      setReservationsArray(promise.data.schedules);
      return;
    }

    //return renderToast(
    //  "error",
    //  "Erro ao carregar serviços, por favor recarregue a página"
    //);
  }, [userData]);

  const openCancelConfirmation = useCallback((eventId) => {
    document.body.style.overflow = "hidden";
    setOpenModal(true);
    setEventId(eventId);
  }, []);


  return (
    <>
      <HeaderSection page="profile" title="Agendamentos" />

      {reservationsArray === null ? (
        <Loading />
      ) : (
        <ProfileReservationsSection
          reservationsArray={reservationsArray}
          openCancelConfirmation={openCancelConfirmation}
        />
      )}

      <CancelReservationModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        renderPage={renderPage}
        eventId={eventId}
      />
    </>
  );
}
