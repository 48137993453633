import { memo } from "react";
import {
  Container,
  Category,
  Title,
  Services,
  Service,
  NamePrice,
  Description,
  ReadMore,
} from "./style";
import { useEffect } from "react";

import defaultProductImage from "../../assets/default-product.jpg";

function ProductsSection({
  readMore,
  productsData,
  personalizationData
}) {

  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);


  useEffect(() => {
    if (window.location.hash) {
      const divId = window.location.hash.substring(1);
      const div = document.getElementById(divId);
      if (div) {
        div.scrollIntoView();
      }
    }
  }, []);

  return (
    <Container dbc={personalizationData?.salon_color}>
      <Category key={1}>
        <h1 style={{color: personalizationData?.less_dark_background_color}} id="session-products">PRODUTOS</h1>
        <Services>
          {productsData?.map((product) => {
            return (
              <div key={product?.id} className='col-lg-12 mb-4'>
                <div className='card rounded-0'>
                  <img style={{borderRadius: '0px', height: '300px', objectFit: "cover", borderStyle: "solid", borderWidth: "3px"}} src={product?.image?.url ? product?.image?.url : defaultProductImage} className='card-img-top'/>
                  <div className='card-body'>
                    <div>
                      <NamePrice fc={personalizationData?.font_color}>
                        <p style={{fontWeight: "bold"}}>{product?.title}</p>
                        <p>{`${numberFormat(product?.price)}`}</p>
                      </NamePrice>
                    </div>
                    {product.description && (<div>
                      <Description dc={personalizationData?.description_color}>{product?.description}</Description>
                      {product?.description !== "" && product.description !== null && product.description.length > 100 && (
                        <ReadMore sc={personalizationData?.salon_color} onClick={() => readMore(product)}>
                          Ler mais...
                        </ReadMore>
                      )}
                    </div>)}
                  </div>
                </div>
              </div>
            );
          })}
        </Services>
      </Category>
    </Container>
  );
}

export default memo(ProductsSection);