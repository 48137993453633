import { memo } from "react";
import { renderDotsLoading } from "../../utils/renderDotsLoading";
import { MdOutlineMenu } from "react-icons/md";

import { useState } from "react";
import { useEffect } from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaUser } from "react-icons/fa";

import { Nav, NavBarNav } from "./style";


function getActiveTab(path){  
  if(path == window.location.pathname){
    return "active";
  }else{
    return "";
  }
}

function HeaderMenu({
  openAuthenticationModal,
  navTo,
  userIsLoggedIn,
  loadingUserValidation,
  logout,
  businessData,
  slugData,
  userData,
  personalizationData
}) {

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const userAgent = window.navigator.userAgent.toLowerCase();
  const isAndroid = userAgent.indexOf("android") > -1;
  const isiOS = /(ipad|iphone|ipod)/i.test(userAgent);

  const closedModalPwa = localStorage.getItem("closedModalPwa");

  const [shouldShowModalPwa, setShouldShowModalPwa] = useState(((isAndroid || isiOS) && !window.matchMedia('(display-mode: standalone)').matches) && (!closedModalPwa) );

  const handleClose = () => {
    localStorage.setItem("closedModalPwa", "true");
    setShouldShowModalPwa(false)
  };
  
  useEffect(() => {
    // Adiciona um listener para o evento beforeinstallprompt
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Remove o listener quando o componente é desmontado
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  // Manipula o evento beforeinstallprompt e armazena o prompt de instalação
  const handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    setDeferredPrompt(event);
  };

  // Manipula o clique no botão de instalação do PWA
  const handleInstallPwaClick = () => {
    if (deferredPrompt) {
      // Exibe o prompt de instalação
      deferredPrompt.prompt();
      // Aguarda a resposta do usuário
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('Usuário aceitou a instalação do PWA');
        } else {
          console.log('Usuário rejeitou a instalação do PWA');
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <>
      <Nav mainColorDark={personalizationData?.description_color} mainColorLigth={personalizationData?.less_dark_background_color} className="navbar navbar-expand-lg">
        <div className="container">
          <div className="logo-wrapper" onClick={() => navTo("/"+slugData)}>
            <a className="logo">
              <img src={businessData?.logo?.url} className="logo-img" alt="" />
            </a>
          </div>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"><MdOutlineMenu /></span>
          </button>
          

          <div className="collapse navbar-collapse" id="navbar">
            <NavBarNav mainColorLigth={personalizationData?.less_dark_background_color} className="navbar-nav ms-auto">
              <li className="nav-item"><a className={`nav-link ${getActiveTab("/"+slugData)}`} href={"/"+slugData}>Início</a></li>
              <li className="nav-item"><a className={`nav-link ${getActiveTab("/"+slugData+"/servicos")}`} href={"/"+slugData+"/servicos"}>Serviços</a></li>
              <li className="nav-item"><a className={`nav-link ${getActiveTab("/"+slugData+"/servicos#session-products")}`} href={"/"+slugData+"/servicos#session-products"}>Produtos</a></li>
              {personalizationData && personalizationData.show_about_menu && <li className="nav-item"><a className={`nav-link ${getActiveTab("/"+slugData+"/sobre")}`} href={"/"+slugData+"/sobre"}>Sobre</a></li>}
              <li className="nav-item"><a className={`nav-link ${getActiveTab("/"+slugData+"/contato")}`} href={"/"+slugData+"/contato"}>Contato</a></li>

              {loadingUserValidation ? (
                <li className="nav-item"><a className="nav-link">{renderDotsLoading()}</a></li>
              ) : (
                <>
                  {userIsLoggedIn ? (
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"> <FaUser /> Olá, {userData?.name} <i className="ti-angle-down"></i></a>
                      <ul className="dropdown-menu">
                        <li><a href={"/"+slugData+"/reservas"} className="dropdown-item"><span>Agendamentos</span></a></li>
                        <li><a href={"/"+slugData+"/cadastro"} className="dropdown-item"><span>Cadastro</span></a></li>
                        <li><a onClick={logout} className="dropdown-item"><span>Sair</span></a></li>
                      </ul>
                    </li>
                  ) : (
                    <li style={{alignContent: "center", display: "grid"}} className="nav-item"> <Button onClick={() => {openAuthenticationModal()}} size="sm" variant="outline-light"> Entrar </Button> </li>
                  )}
                </>
              )}
            </NavBarNav>
          </div>
        </div>
      </Nav>
      <Modal show={shouldShowModalPwa} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Aplicativo {businessData?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Olá! Estamos felizes em vê-lo(a) aqui! 😊 Você sabia que pode adicionar nosso aplicativo à tela inicial do seu celular de forma simples?!
        </Modal.Body>
        <Modal.Body>
          { isiOS && (<>
            <p style={{marginBottom: "10px"}}>Toque no ícone de compartilhamento na parte inferior da tela. Geralmente, parece um quadrado com uma seta para cima..</p>
            <p style={{marginBottom: "10px"}}>Em seguida, selecione <span style={{fontWeight: "bold"}}>Adicionar à Tela de Início</span>. </p>
            <p>Pronto! Agora você terá acesso rápido ao nosso aplicativo sempre que precisar.</p>
          </>) }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {isAndroid &&(<Button variant="primary" onClick={handleInstallPwaClick}>
            Instalar
          </Button>)}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default memo(HeaderMenu);
